import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify, {
  VStepper,
  VStepperContent,
  VStepperStep,
  VApp,
  VBtn,
  VTextField,
  VSelect,
  VMenu,
  VBreadcrumbs,
  VIcon,
  VCard,
  VProgressLinear,
  VCarousel,
  VCarouselItem,
  VSlideGroup,
  VSlideItem,
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VStepper,
    VStepperContent,
    VBtn,
    VStepperStep,
    VApp,
    VTextField,
    VSelect,
    VMenu,
    VBreadcrumbs,
    VIcon,
    VCard,
    VProgressLinear,
    VCarousel,
    VCarouselItem,
    VSlideGroup,
    VSlideItem,
  },
});

const opts = {
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
};

export default new Vuetify(opts);
