export const state = () => ({
  loadingCreatePassword: false,
});

export const getters = {};

export const mutations = {
  SET_LOADING_CREATE_PASSWORD(state, payload) {
    state.loadingCreatePassword = payload;
  },
};

export const actions = {
  async createPassword({ commit }, password) {
    try {
      commit('SET_LOADING_CREATE_PASSWORD', true);
      const { data } = await this.$http.post('/api/create_password', {
        password,
      });
      return data.data;
    } finally {
      commit('SET_LOADING_CREATE_PASSWORD', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async resendActivation({ commit }, email) {
    try {
      const { data } = await this.$http.post('/api/auth/resend_activation', {
        email,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },
};
